body, html, #root {
  background: #f8f8f8;
  padding: 0;
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  height: 100%;
}

.flex {
  flex: 1
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.flex-row.end {
  justify-content: flex-end;
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-0 {
  gap: 0px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.p {
  padding: 20px
}

.p-t {
  padding-top: 20px
}

.p-b {
  padding-bottom: 20px
}

.p-l {
  padding-left: 20px
}

.p-r {
  padding-right: 20px
}

.m {
  margin: 20px
}

.m-t {
  margin-top: 20px
}

.m-b {
  margin-bottom: 20px
}

.m-l {
  margin-left: 20px
}

.m-r {
  margin-right: 20px
}